import DataFilterHelper from './DataFilterHelper';
import {
  FILTER_TYPE_CATEGORIES,
  FILTER_TYPE_DATES,
  FILTER_TYPE_DESTINATIONS,
  FILTER_TYPE_SORT,
  FILTER_TYPE_STAFF_PICK,
} from '@/helpers/data-filter/DataFilterTypes';
import { isCurrentGeoPositionExist } from '@/helpers/GeolocationHelper';

class ExhibitionDataFilterHelper extends DataFilterHelper {
  defaultValues = {
    [FILTER_TYPE_DESTINATIONS]: [],
    [FILTER_TYPE_CATEGORIES]: [],
    [FILTER_TYPE_DATES]: [],
    [FILTER_TYPE_STAFF_PICK]: null,
    [FILTER_TYPE_SORT]: null,
  };
  validValuesTypes = {
    array: [FILTER_TYPE_DESTINATIONS, FILTER_TYPE_CATEGORIES, FILTER_TYPE_DATES],
    boolean: [FILTER_TYPE_STAFF_PICK],
  };

  /**
   * @param {Object} groupsWithItems
   * @param {FilterGroupItem[]|null} groupsWithItems.destinations
   * @param {FilterGroupItem[]|null} groupsWithItems.categories
   * @param {FilterGroupItem[]|null} groupsWithItems.dates
   * @returns {FilterGroup[]}
   */
  prepareFilterGroups(groupsWithItems) {
    /**
     * @type {FilterGroup[]}
     */
    const groups = [
      this.addDefaultGroupItems({
        group: groupsWithItems.destinations,
        itemsType: FILTER_TYPE_DESTINATIONS,
      }),
      this.addDefaultGroupItems({
        group: groupsWithItems.categories,
        itemsType: FILTER_TYPE_CATEGORIES,
      }),
      this.addDateGroupItems({
        group: groupsWithItems.dates,
        itemsType: FILTER_TYPE_DATES,
        title: 'Dates',
      }),
      this.addRadioGroupItems({
        itemsType: FILTER_TYPE_STAFF_PICK,
        items: [
          {
            title: 'Show only Staff picks',
            value: true,
          },
        ],
      }),
    ];

    const sortItems = [];

    if (isCurrentGeoPositionExist()) {
      sortItems.unshift({
        title: 'Sort by distance',
        value: { column: 'distance' },
      });
    }

    groups.push(
      this.addRadioGroupItems({
        itemsType: FILTER_TYPE_SORT,
        items: sortItems,
      })
    );

    return groups.filter((v) => v);
  }

  prepareFilterValuesToQueryVariables(groups = {}) {
    const transformed = super.prepareFilterValuesToQueryVariables(groups);

    return {
      filters: {
        destinationSlug: transformed[FILTER_TYPE_DESTINATIONS],
        category: transformed[FILTER_TYPE_CATEGORIES],
        date: transformed[FILTER_TYPE_DATES],
        staff_pick: transformed[FILTER_TYPE_STAFF_PICK],
      },
      sort: transformed[FILTER_TYPE_SORT],
    };
  }
}

export default ExhibitionDataFilterHelper.create();
